<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar
      dense
      card
    >
      <v-menu
        v-model="menu"
        lazy
        close-on-content-click
        transition="scale-transition"
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="monthOf"
          prepend-icon="event"
          readonly
        />
        <v-date-picker
          v-model="monthOf"
          :max="maxDate"
          :allowed-dates="allowedMonths"
          min="2020-03"
          type="month"
          year-icon="date_range"
        />
      </v-menu>
      <v-spacer />
    </v-toolbar>
    <v-card-title class="title">
      Cycles
    </v-card-title>
    <v-alert
      :value="alert"
      type="error"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      :headers="cycleHeaders"
      :items="cycles"
      :loading="loading"
      item-key="cycle"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-center">
            {{ props.item.cycle }}
          </td>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
          <td class="text-xs-center">
            {{ props.item.winMark }}
          </td>
          <td class="text-xs-center">
            {{ props.item.cycleCap }}
          </td>
          <td class="text-xs-left">
            {{ props.item.admin }}
          </td>
          <td class="text-xs-left">
            {{ props.item.remark }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <p />
    <v-card-title class="title">
      Rewards
    </v-card-title>
    <v-sheet>
      <v-tooltip
        v-if="rewards.length"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            absolute
            fab
            dark
            small
            right
            top
            color="green"
            v-on="on"
            @click="exportRewards"
          >
            <v-icon dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </template>
        <span>
          Export Report
        </span>
      </v-tooltip>
    </v-sheet>
    <v-data-table
      :headers="rewardHeaders"
      :items="rewards"
      :loading="loading"
      item-key="reward"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-center">
            {{ props.item.cycle }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-center">
            <v-icon color="orange">
              {{ props.item.onex ? 'mdi-check-circle' : 'mdi-close-circle' }}
            </v-icon>
          </td>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
          <td class="text-xs-left">
            {{ props.item.reward }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <p />
    <v-card-title class="title">
      Candidates
    </v-card-title>
    <v-card-text>
      <v-select
        v-model="candidateCycle"
        :items="selectCycle"
        box
        label="Select cycle"
      /><v-spacer />
    </v-card-text>
    <v-data-table
      :headers="candidateHeaders"
      :items="candidatesInCycle"
      :loading="loading"
      item-key="candidate"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-center">
            <v-icon color="orange">
              {{ props.item.onex ? 'mdi-check-circle' : 'mdi-close-circle' }}
            </v-icon>
          </td>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
          <td class="text-xs-left">
            {{ props.item.entry }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <p />
    <v-card-title class="title">
      Daily Breakdown
    </v-card-title>
    <v-data-table
      :headers="dailyHeaders"
      :items="daily"
      :loading="loading"
      item-key="daily"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-center">
            {{ props.item.day }}
          </td>
          <td class="text-xs-center">
            {{ props.item.qualified }}
          </td>
          <td class="text-xs-center">
            {{ props.item.winner }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <p />
    <v-card-title class="title">
      Activation Tokens
    </v-card-title>
    <v-sheet>
      <v-tooltip
        v-if="activationTokens.length"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            absolute
            fab
            dark
            small
            right
            top
            color="green"
            v-on="on"
            @click="exportActivationTokens"
          >
            <v-icon dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </template>
        <span>
          Export Report
        </span>
      </v-tooltip>
    </v-sheet>
    <v-data-table
      :headers="activationTokenHeaders"
      :items="activationTokens"
      :loading="loading"
      item-key="activationTokens"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
          <td class="text-xs-center">
            {{ props.item.prepaid }}
          </td>
          <td class="text-xs-center">
            {{ props.item.prepaidSyox30 }}
          </td>
          <td class="text-xs-center">
            {{ props.item.prepaid_ol }}
          </td>
          <td class="text-xs-center">
            {{ props.item.prepaidSyox30_ol }}
          </td>
          <!--td class="text-xs-center">{{ props.item.b10 }}</td-->
          <td class="text-xs-center">
            {{ props.item.b39 }}
          </td>
          <td class="text-xs-center">
            {{ props.item.b39_ol }}
          </td>
          <td class="text-xs-center">
            {{ props.item.nu }}
          </td>
          <td class="text-xs-center">
            {{ props.item.nuSyox30 }}
          </td>
          <td class="text-xs-center">
            {{ props.item.nu_ol }}
          </td>
          <td class="text-xs-center">
            {{ props.item.nuSyox30_ol }}
          </td>
          <td class="text-xs-center">
            {{ props.item.b59Series }}
          </td>
          <td class="text-xs-center">
            {{ props.item.b59Series_ol }}
          </td>
          <td class="text-xs-center">
            {{ props.item.b89Series }}
          </td>
          <td class="text-xs-center">
            {{ props.item.b89Series_ol }}
          </td>
          <td class="text-xs-center">
            {{ props.item.b149 }}
          </td>
          <td class="text-xs-center">
            {{ props.item.b149_ol }}
          </td>
          <td class="text-xs-center primary--text">
            {{ props.item.token }}
          </td>
        </tr>
      </template>
      <template #footer>
        <tr>
          <td>&nbsp;</td>
          <td class="text-xs-center primary--text">
            {{ totalPrepaid }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalPrepaidSyox30 }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalPrepaid_ol }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalPrepaidSyox30_ol }}
          </td>
          <!--td class="text-xs-center primary--text">{{ totalB10 }}</td-->
          <td class="text-xs-center primary--text">
            {{ totalB39 }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalB39_ol }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalNu }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalNuSyox30 }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalNu_ol }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalNuSyox30_ol }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalB59Series }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalB59Series_ol }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalB89Series }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalB89Series_ol }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalB149 }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalB149_ol }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalToken }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <p />
    <v-card-title class="title">
      Recruitment Tokens
    </v-card-title>
    <v-sheet>
      <v-tooltip
        v-if="recruitmentTokens.length"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            absolute
            fab
            dark
            small
            right
            top
            color="green"
            v-on="on"
            @click="exportRecruitmentTokens"
          >
            <v-icon dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </template>
        <span>
          Export Report
        </span>
      </v-tooltip>
    </v-sheet>
    <v-data-table
      :headers="recruitmentTokenHeaders"
      :items="recruitmentTokens"
      :loading="loading"
      item-key="recruitmentTokens"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
          <td class="text-xs-center">
            {{ props.item.basic }}
          </td>
          <td class="text-xs-center">
            {{ props.item.normal }}
          </td>
          <td class="text-xs-center primary--text">
            {{ props.item.token }}
          </td>
        </tr>
      </template>
      <template #footer>
        <tr>
          <td>&nbsp;</td>
          <td class="text-xs-center primary--text">
            {{ totalRecruitmentBasic }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalRecruitmentNormal }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalRecruitmentToken }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <p />
    <v-card-title class="title">
      Token Summary
    </v-card-title>
    <v-sheet>
      <v-tooltip
        v-if="tokenSummary.length"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            absolute
            fab
            dark
            small
            right
            top
            color="green"
            v-on="on"
            @click="exportTokenSummary"
          >
            <v-icon dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </template>
        <span>
          Export Report
        </span>
      </v-tooltip>
    </v-sheet>
    <v-data-table
      :headers="tokenSummaryHeaders"
      :items="tokenSummary"
      :loading="loading"
      item-key="tokenSummary"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
          <td class="text-xs-left">
            {{ props.item.dreg }}
          </td>
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td class="text-xs-center">
            {{ props.item.activation }}
          </td>
          <td class="text-xs-center">
            {{ props.item.recruitment }}
          </td>
          <td class="text-xs-center primary--text">
            {{ props.item.total }}
          </td>
        </tr>
      </template>
      <template #footer>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td class="text-xs-center primary--text">
            {{ totalActivations }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalRecruitments }}
          </td>
          <td class="text-xs-center primary--text">
            {{ totalSummaryToken }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </wide-panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import zipcelx from 'zipcelx'

const title = 'Car Bonanza'

export default {
  components: {
    // Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel')
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'DMT Kits', disabled: false, to: '/support/dmt_kits',
        },
        {
          text: 'Reports', disabled: false, to: '/support/dmt_reports',
        },
        {
          text: title, disabled: true,
        },
      ],
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local(2020, 1, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      cycles: [],
      cycleHeaders: [
        {
          text: 'Cycle',
          align: 'center',
          sortable: true,
          value: 'cycle',
        },
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
        },
        {
          text: 'Win Mark',
          align: 'center',
          sortable: true,
          value: 'winMark',
        },
        {
          text: 'Cycle Cap',
          align: 'center',
          sortable: true,
          value: 'cycleCap',
        },
        {
          text: 'Admin',
          align: 'left',
          sortable: false,
          value: 'admin',
        },
        {
          text: 'Remark',
          align: 'left',
          sortable: false,
          value: 'remark',
        },
      ],
      rewards: [],
      rewardHeaders: [
        {
          text: 'Cycle',
          align: 'center',
          sortable: true,
          value: 'cycle',
        },
        {
          text: 'Msisdn',
          align: 'left',
          sortable: true,
          value: 'onex',
        },
        {
          text: 'OneXOX',
          align: 'center',
          sortable: true,
          value: 'onex',
        },
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
        },
        {
          text: 'Reward',
          align: 'left',
          sortable: true,
          value: 'reward',
        },
      ],
      candidates: [],
      candidateHeaders: [
        {
          text: 'Msisdn',
          align: 'left',
          sortable: true,
          value: 'onex',
        },
        {
          text: 'OneXOX',
          align: 'center',
          sortable: true,
          value: 'onex',
        },
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
        },
      ],
      daily: [],
      dailyHeaders: [
        {
          text: 'Day',
          align: 'center',
          sortable: true,
          value: 'day',
        },
        {
          text: 'Qualified Dealers',
          align: 'center',
          sortable: true,
          value: 'qualified',
        },
        {
          text: 'Winner',
          align: 'center',
          sortable: true,
          value: 'winner',
        },
      ],
      candidateCycle: 0,
      selectCycle: [],
      loading: false,
      alert: false,
      alertMessage: null,
      maxDate: '',
      menu: false,
      monthOf: null,
      title,
      activationTokens: [],
      activationTokenHeaders: [
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Prepaid',
          align: 'center',
          sortable: true,
          value: 'prepaid',
        },
        {
          text: 'SYOX30',
          align: 'center',
          sortable: true,
          value: 'prepaidSyox30',
        },
        {
          text: 'Prepaid (A)',
          align: 'center',
          sortable: true,
          value: 'prepaid_ol',
        },
        {
          text: 'SYOX30 (A)',
          align: 'center',
          sortable: true,
          value: 'prepaidSyox30_ol',
        },
        /*
        {
          text: 'B10+RM20',
          align: 'center',
          sortable: true,
          value: 'b10'
        },
        */
        {
          text: 'B39',
          align: 'center',
          sortable: true,
          value: 'b39',
        },
        {
          text: 'B39 (A)',
          align: 'center',
          sortable: true,
          value: 'b39_ol',
        },
        {
          text: 'NU',
          align: 'center',
          sortable: true,
          value: 'nu',
        },
        {
          text: 'SYOX30',
          align: 'center',
          sortable: true,
          value: 'nuSyox30',
        },
        {
          text: 'NU (A)',
          align: 'center',
          sortable: true,
          value: 'nu_ol',
        },
        {
          text: 'SYOX30 (A)',
          align: 'center',
          sortable: true,
          value: 'nuSyox30_ol',
        },
        {
          text: 'B59 Series',
          align: 'center',
          sortable: true,
          value: 'b59Series',
        },
        {
          text: 'B59 Series (A)',
          align: 'center',
          sortable: true,
          value: 'b59Series_ol',
        },
        {
          text: 'B89 Series',
          align: 'center',
          sortable: true,
          value: 'b89Series',
        },
        {
          text: 'B89 Series (A)',
          align: 'center',
          sortable: true,
          value: 'b89Series_ol',
        },
        {
          text: 'B149',
          align: 'center',
          sortable: true,
          value: 'b149',
        },
        {
          text: 'B149 (A)',
          align: 'center',
          sortable: true,
          value: 'b149_ol',
        },
        {
          text: 'Token',
          align: 'center',
          sortable: true,
          value: 'token',
        },
      ],
      recruitmentTokens: [],
      recruitmentTokenHeaders: [
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Basic',
          align: 'center',
          sortable: true,
          value: 'basic',
        },
        {
          text: 'Normal',
          align: 'center',
          sortable: true,
          value: 'normal',
        },
        {
          text: 'Token',
          align: 'center',
          sortable: true,
          value: 'token',
        },
      ],
      tokenSummary: [],
      tokenSummaryHeaders: [
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'DREG',
          align: 'left',
          sortable: true,
          value: 'dreg',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Activation Token',
          align: 'center',
          sortable: true,
          value: 'activation',
        },
        {
          text: 'Recruitment Token',
          align: 'center',
          sortable: true,
          value: 'recruitment',
        },
        {
          text: 'Total Token',
          align: 'center',
          sortable: true,
          value: 'total',
        },
      ],
      totalRecruitmentBasic: 0,
      totalRecruitmentNormal: 0,
      totalRecruitmentToken: 0,
      totalActivations: 0,
      totalRecruitments: 0,
      totalSummaryToken: 0,
      totalToken: 0,
      totalPrepaidSeries: 0,
      totalB59Series: 0,
      totalB89Series: 0,
      totalPrepaidSeries_ol: 0,
      totalB59Series_ol: 0,
      totalB89Series_ol: 0,
      totalPrepaid: 0,
      totalPrepaidSyox30: 0,
      totalB10: 0,
      totalB39: 0,
      totalNu: 0,
      totalNuSyox30: 0,
      totalB149: 0,
      totalPrepaid_ol: 0,
      totalPrepaidSyox30_ol: 0,
      totalB10_ol: 0,
      totalB39_ol: 0,
      totalNu_ol: 0,
      totalNuSyox30_ol: 0,
      totalB149_ol: 0,
      rewardsHeader:
        [
          {
            value: 'Cycle',
            type: 'string',
          },
          {
            value: 'Msisdn',
            type: 'string',
          },
          {
            value: 'Claimer',
            type: 'string',
          },
          {
            value: 'Dealer',
            type: 'string',
          },
          {
            value: 'Entry',
            type: 'string',
          },
          {
            value: 'Reward',
            type: 'string',
          },
        ],
      dealerRecruitmentTokenHeader:
        [
          {
            value: 'Dealer',
            type: 'string',
          },
          {
            value: 'Basic',
            type: 'string',
          },
          {
            value: 'Normal',
            type: 'string',
          },
          {
            value: 'Token',
            type: 'string',
          },
        ],
      dealerTokenSummaryHeader:
        [
          {
            value: 'Dealer',
            type: 'string',
          },
          {
            value: 'DREG',
            type: 'string',
          },
          {
            value: 'Name',
            type: 'string',
          },
          {
            value: 'Activation Token',
            type: 'string',
          },
          {
            value: 'Recruitment Token',
            type: 'string',
          },
          {
            value: 'Total Token',
            type: 'string',
          },
        ],
      dealerActivationTokenHeader:
        [
          {
            value: 'Dealer',
            type: 'string',
          },
          {
            value: 'Prepaid',
            type: 'string',
          },
          {
            value: 'SYOX30',
            type: 'string',
          },
          {
            value: 'Prepaid (A)',
            type: 'string',
          },
          {
            value: 'SYOX30',
            type: 'string',
          },
          /*
          {
            value: 'B10+RM20',
            type: 'string'
          },
          */
          {
            value: 'B39',
            type: 'string',
          },
          {
            value: 'B39 (A)',
            type: 'string',
          },
          {
            value: 'NU',
            type: 'string',
          },
          {
            value: 'SYOX30',
            type: 'string',
          },
          {
            value: 'NU (A)',
            type: 'string',
          },
          {
            value: 'SYOX30',
            type: 'string',
          },
          {
            value: 'B59 Series',
            type: 'string',
          },
          {
            value: 'B59 Series (A)',
            type: 'string',
          },
          {
            value: 'B89 Series',
            type: 'string',
          },
          {
            value: 'B89 Series (A)',
            type: 'string',
          },
          {
            value: 'B149',
            type: 'string',
          },
          {
            value: 'B149 (A)',
            type: 'string',
          },
          {
            value: 'Token',
            type: 'string',
          },
        ],
    }
  },
  computed: {
    candidatesInCycle: function () {
      let candidates = []
      for (const [cycle, candidate] of Object.entries(this.candidates)) {
        if (Number(cycle) === Number(this.candidateCycle)) candidates = candidate
      }
      return candidates
    },
  },
  watch: {
    monthOf: function (val) {
      this.getCarBonanza(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    getCarBonanza: function (month) {
      const param = month ? { month } : {}
      this.loading = true
      this.$rest.get('getCarBonanza.php', createGetParams(param))
        .then(function (response) {
          let maxCycle = 0
          let minCycle = 1000000
          this.cycles = response.data.cycle
          this.rewards = response.data.reward
          this.daily = response.data.daily
          this.candidates = response.data.candidate
          this.recruitmentTokens = []
          this.activationTokens = []
          this.tokenSummary = []
          this.selectCycle = []
          for (const cycle of Object.keys(this.candidates)) {
            if (minCycle > Number(cycle)) {
              minCycle = Number(cycle)
            }
            if (maxCycle < Number(cycle)) {
              maxCycle = Number(cycle)
            }
          }
          for (var i = maxCycle; i >= minCycle; i--) {
            this.selectCycle.push(i)
          }
          this.candidateCycle = maxCycle
          this.totalToken = 0
          this.totalPrepaidSeries = 0
          this.totalB59Series = 0
          this.totalB89Series = 0
          this.totalPrepaidSeries_ol = 0
          this.totalB59Series_ol = 0
          this.totalB89Series_ol = 0
          this.totalPrepaid = 0
          this.totalB10 = 0
          this.totalB39 = 0
          this.totalNu = 0
          this.totalB149 = 0
          this.totalPrepaid_ol = 0
          this.totalB10_ol = 0
          this.totalB39_ol = 0
          this.totalNu_ol = 0
          this.totalB149_ol = 0
          this.totalRecruitmentBasic = 0
          this.totalRecruitmentNormal = 0
          this.totalRecruitmentToken = 0
          this.totalActivations = 0
          this.totalRecruitments = 0
          this.totalSummaryToken = 0
          for (const [dealer, token] of Object.entries(response.data.recruitment)) {
            const item = {
              dealer,
              basic: token.basic * 1,
              normal: token.normal * 1,
              token: token.token * 1,
            }

            this.totalRecruitmentBasic += token.basic
            this.totalRecruitmentNormal += token.normal
            this.totalRecruitmentToken += token.token
            this.recruitmentTokens.push(item)
          }
          for (const [dealer, token] of Object.entries(response.data.tokenSummary)) {
            const item = {
              dealer,
              dreg: token.dreg,
              name: token.name,
              activation: token.activation * 1,
              recruitment: token.recruitment * 1,
              total: token.total * 1,
            }

            this.totalActivations += token.activation
            this.totalRecruitments += token.recruitment
            this.totalSummaryToken += token.total
            this.tokenSummary.push(item)
          }
          for (const [dealer, token] of Object.entries(response.data.token)) {
            const item = {
              dealer,
              msisdn: token.msisdn,
              // name: token.name,
              prepaidSeries: token.prepaidSeries * 1,
              b59Series: token.b59Series * 1,
              b89Series: token.b89Series * 1,
              prepaidSeries_ol: token.prepaidSeries_ol * 1,
              b59Series_ol: token.b59Series_ol * 1,
              b89Series_ol: token.b89Series_ol * 1,
              prepaid: token.prepaid * 1,
              prepaidSyox30: token.prepaidSyox30 * 1,
              b10: token.b10 * 1,
              b39: token.b39 * 1,
              nu: token.nu * 1,
              nuSyox30: token.nuSyox30 * 1,
              b149: token.b149 * 1,
              prepaid_ol: token.prepaid_ol * 1,
              prepaidSyox30_ol: token.prepaidSyox30_ol * 1,
              b10_ol: token.b10_ol * 1,
              b39_ol: token.b39_ol * 1,
              nu_ol: token.nu_ol * 1,
              nuSyox30_ol: token.nuSyox30_ol * 1,
              b149_ol: token.b149_ol * 1,
              token: token.token * 1,
            }
            this.totalToken += item.token
            this.totalPrepaid += item.prepaid
            this.totalPrepaidSyox30 += item.prepaidSyox30
            this.totalB10 += item.b10
            this.totalB39 += item.b39
            this.totalNu += item.nu
            this.totalNuSyox30 += item.nuSyox30
            this.totalPrepaid_ol += item.prepaid_ol
            this.totalPrepaidSyox30_ol += item.prepaidSyox30_ol
            this.totalB10_ol += item.b10_ol
            this.totalB39_ol += item.b39_ol
            this.totalNu_ol += item.nu_ol
            this.totalNuSyox30_ol += item.nuSyox30_ol
            this.totalB59Series += item.b59Series
            this.totalB89Series += item.b89Series
            this.totalB149 += item.b149
            this.totalB59Series_ol += item.b59Series_ol
            this.totalB89Series_ol += item.b89Series_ol
            this.totalB149_ol += item.b149_ol
            this.activationTokens.push(item)
          }
          this.loading = false
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alert = true
          this.loading = false
        })
    },
    exportTokenSummary: function () {
      const config = {
        filename: 'CarBonanza-token-summary-' + this.monthOf,
        sheet: {
          data: [],
        },
      }

      config.sheet.data.push(this.dealerTokenSummaryHeader)
      this.tokenSummary.forEach(item => {
        const dealers = [
          {
            value: item.dealer,
            type: 'string',
          },
          {
            value: item.dreg,
            type: 'string',
          },
          {
            value: item.name,
            type: 'string',
          },
          {
            value: item.activation,
            type: 'number',
          },
          {
            value: item.recruitment,
            type: 'number',
          },
          {
            value: item.total,
            type: 'number',
          },
        ]

        config.sheet.data.push(dealers)
      })

      zipcelx(config)
    },
    exportRewards: function () {
      const config = {
        filename: 'CarBonanza-rewards-' + this.monthOf,
        sheet: {
          data: [],
        },
      }

      config.sheet.data.push(this.rewardsHeader)
      this.rewards.forEach(item => {
        const winners = [
          {
            value: item.cycle,
            type: 'number',
          },
          {
            value: item.msisdn,
            type: 'string',
          },
          {
            value: item.onex ? 'OneXOX' : 'XOX',
            type: 'string',
          },
          {
            value: item.dealer,
            type: 'string',
          },
          {
            value: item.entry,
            type: 'string',
          },
          {
            value: item.reward,
            type: 'string',
          },
        ]

        config.sheet.data.push(winners)
      })

      zipcelx(config)
    },
    exportRecruitmentTokens: function () {
      const config = {
        filename: 'CarBonanza-recruitment-' + this.monthOf,
        sheet: {
          data: [],
        },
      }

      config.sheet.data.push(this.dealerRecruitmentTokenHeader)
      this.recruitmentTokens.forEach(item => {
        const dealers = [
          {
            value: item.dealer,
            type: 'string',
          },
          {
            value: item.basic,
            type: 'number',
          },
          {
            value: item.normal,
            type: 'number',
          },
          {
            value: item.token,
            type: 'number',
          },
        ]

        config.sheet.data.push(dealers)
      })

      zipcelx(config)
    },
    exportActivationTokens: function () {
      const config = {
        filename: 'CarBonanza-activation-' + this.monthOf,
        sheet: {
          data: [],
        },
      }

      config.sheet.data.push(this.dealerActivationTokenHeader)
      this.activationTokens.forEach(item => {
        const dealers = [
          {
            value: item.dealer,
            type: 'string',
          },
          {
            value: item.prepaid,
            type: 'number',
          },
          {
            value: item.prepaidSyox30,
            type: 'number',
          },
          {
            value: item.prepaid_ol,
            type: 'number',
          },
          {
            value: item.prepaidSyox30_ol,
            type: 'number',
          },
          /*
          {
            value: item.b10,
            type: 'number'
          },
          */
          {
            value: item.b39,
            type: 'number',
          },
          {
            value: item.b39_ol,
            type: 'number',
          },
          {
            value: item.nu,
            type: 'number',
          },
          {
            value: item.nuSyox30,
            type: 'number',
          },
          {
            value: item.nu_ol,
            type: 'number',
          },
          {
            value: item.nuSyox30_ol,
            type: 'number',
          },
          {
            value: item.b59Series,
            type: 'number',
          },
          {
            value: item.b59Series_ol,
            type: 'number',
          },
          {
            value: item.b89Series,
            type: 'number',
          },
          {
            value: item.b89Series_ol,
            type: 'number',
          },
          {
            value: item.b149,
            type: 'number',
          },
          {
            value: item.b149_ol,
            type: 'number',
          },
          {
            value: item.token,
            type: 'number',
          },
        ]

        config.sheet.data.push(dealers)
      })

      zipcelx(config)
    },
  },
}
</script>
