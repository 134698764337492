
<template>
  <v-scale-transition>
    <wide-panel
      :title="title"
      :breadcrumbs="breadcrumbs"
    >
      <v-toolbar
        card
        extended
      >
        <v-container fluid>
          <v-layout row>
            <v-flex xs3>
              <v-menu
                ref="menu"
                v-model="menu"
                lazy
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
              >
                <v-text-field
                  slot="activator"
                  v-model="day"
                  :max="today"
                  prepend-icon="event"
                  label="Select Subscription date"
                  readonly
                />
                <v-date-picker
                  ref="picker"
                  v-model="day"
                  :show-current="false"
                  actions
                  no-title
                  @change="saveMenu"
                />
              </v-menu>
            </v-flex>
            <v-flex xs2>
              <v-select
                v-model="select"
                :items="selects"
                item-text="text"
                item-value="value"
                label="User By"
              />
            </v-flex>
            <v-flex xs2>
              <v-select
                v-model="filter"
                :items="filters"
                item-text="text"
                item-value="value"
                label="Filter by"
              />
            </v-flex>
            <v-flex xs4>
              <v-select
                v-if="filter === 'stat'"
                v-model="stat"
                :items="status"
                item-text="text"
                item-value="value"
                label="Select status"
              />
              <v-text-field
                v-if="filter !== null && filter !== 'stat'"
                v-model="search"
                label="Enter search string"
                clearable
              />
            </v-flex>
            <v-spacer />
            <v-card-actions>
              <v-spacer />
              <v-btn
                v-if="filter !== null"
                color="primary"
                dark
                class="mb-2"
                @click="refreshList()"
              >
                Search
              </v-btn>
            </v-card-actions>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  fab
                  dark
                  small
                  :disabled="!enableExport"
                  :loading="!enableExport"
                  color="green"
                  v-on="on"
                  @click="exportReport"
                >
                  <v-icon dark>
                    mdi-microsoft-excel
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Export Report
              </span>
            </v-tooltip>
          </v-layout>
        </v-container>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :loading="loading"
            :items="items"
            :rows-per-page-items="[10, 25, 50, 100]"
            :expand="true"
            item-key="lastUpdate"
            class="elevation-1"
            disable-initial-sort
          >
            <template #items="props">
              <tr>
                <td
                  v-if="props.item.msisdn"
                  class="text-xs-left"
                >
                  {{ props.item.msisdn }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.name"
                  class="text-xs-left"
                >
                  {{ props.item.name }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
                <td
                  v-if="props.item.nric"
                  class="text-xs-left"
                >
                  {{ props.item.nric }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.plan"
                  class="text-xs-left"
                >
                  {{ props.item.plan }} {{ props.item.code }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.introducer"
                  class="text-xs-center"
                >
                  {{ props.item.introducer }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.subscriptionDate"
                  class="text-xs-left"
                >
                  {{ props.item.subscriptionDate }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.areaDealer"
                  class="text-xs-left"
                >
                  {{ props.item.areaDealer }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.masterDealer"
                  class="text-xs-left"
                >
                  {{ props.item.masterDealer }}
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  -
                </td>
                <td
                  v-if="props.item.status"
                  class="text-xs-left"
                >
                  {{ props.item.status }}
                </td>
                <td
                  v-else
                  class="text-xs-left"
                >
                  -
                </td>
              </tr>
            </template>
            <template slot="no-data">
              <v-alert
                :value="true"
                :type="error ? 'error' : 'info'"
              >
                {{ noDataText }}
              </v-alert>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </wide-panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams } from '@/rest'
import { DateTime } from 'luxon'
import zipcelx from 'zipcelx'

const pageTitle = 'User Registration List'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'DMT Kits', disabled: false, to: '/support/dmt_kits',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      today: DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL-dd'),
      activeItem: null,
      alert: false,
      alertType: null,
      alertMessage: null,
      maxDate: '',
      error: null,
      menu: false,
      day: null,
      select: null,
      filter: null,
      filters: [
        {
          text: 'Dealer',
          value: 'dealer',
        },
        {
          text: 'Status',
          value: 'stat',
        },
      ],
      stat: null,
      status: [
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Portout',
          value: 'portout',
        },
        {
          text: 'Terminate',
          value: 'terminated',
        },
        {
          text: 'Suspended',
          value: 'suspended',
        },
      ],
      headers: [
        {
          align: 'left',
          sortable: true,
          text: 'MSISDN',
          value: 'msisdn',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Name',
          value: 'name',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Nric',
          value: 'nric',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Plan',
          value: 'plan',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Introducer',
          value: 'dealer',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Subscription Date',
          value: 'subscriptionDate',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Area Dealer',
          value: 'areaDealer',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Master Dealer',
          value: 'masterDealer',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Status',
          value: 'status',
        },
      ],
      items: [],
      enableExport: true,
      exportedReport: [],
      exportReportHeader:
      [
        {
          value: 'Name',
          type: 'string',
        },
        {
          value: 'Msisdn',
          type: 'string',
        },
        {
          value: 'Nric',
          type: 'string',
        },
        {
          value: 'Plan',
          type: 'string',
        },
        {
          value: 'Code',
          type: 'string',
        },
        {
          value: 'Introducer',
          type: 'string',
        },
        {
          value: 'Subscription Date',
          type: 'string',
        },
        {
          value: 'Area Dealer',
          type: 'string',
        },
        {
          value: 'Master Dealer',
          type: 'string',
        },
        {
          value: 'Status',
          type: 'string',
        },
      ],
      selects: [
        {
          text: 'All',
          value: 'all',
        },
        {
          text: 'OneXOX',
          value: 'onex',
        },
        {
          text: 'XOX',
          value: 'xox',
        },
      ],
      loading: false,
      snackbar: false,
      snackbarMessage: null,
      search: null,
      searchId: null,
      source: null,
      title: pageTitle,
    }
  },
  computed: {
    noDataText: function () {
      return this.error ? this.error : "There's nothing to display."
    },
  },
  watch: {
    filter: function () {
      (this.search || this.stat || this.day || this.select) && this.resetList()
      this.search = null
      this.stat = null
    },
    // stat: function () {
    //   this.refreshList()
    // },
    // search: function (val) {
    //   clearTimeout(this.searchId)
    //   this.cancelRequest()
    //   if (val) {
    //     this.searchId = setTimeout(() => {
    //       this.refreshList()
    //     }, 1000)
    //   } else {
    //     this.resetList()
    //   }
    // },
    exportedReport: function () {
      const config = {
        filename: this.today,
        sheet: {
          data: [],
        },
      }
      config.sheet.data.push(this.exportReportHeader)
      this.exportedReport.forEach(item => {
        const transaction = [
          {
            value: item.name,
            type: 'string',
          },
          {
            value: item.msisdn,
            type: 'string',
          },
          {
            value: item.nric,
            type: 'string',
          },
          {
            value: item.plan,
            type: 'string',
          },
          {
            value: item.code,
            type: 'string',
          },
          {
            value: item.introducer,
            type: 'string',
          },
          {
            value: item.subscriptionDate,
            type: 'string',
          },
          {
            value: item.areaDealer,
            type: 'string',
          },
          {
            value: item.masterDealer,
            type: 'string',
          },
          {
            value: item.status,
            type: 'string',
          },
        ]
        config.sheet.data.push(transaction)
      })
      zipcelx(config)
      this.enableExport = true
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.day = now.toFormat('yyyy-LL-dd')
    this.select = 'All'
  },
  methods: {
    async getUserList (report = false) {
      const filters = this.getFilters()
      if (!filters) return { items: [] }
      this.loading = true
      const parameters = {
      }
      Object.assign(parameters, filters)
      const apiParameters = createGetParams(parameters)
      try {
        this.source = this.$rest.CancelToken.source()
        Object.assign(apiParameters, { cancelToken: this.source.token })
        const response = await this.$rest.get('getUserRegistrationList.php', apiParameters)
        this.error = null
        this.loading = false
        return response.data
      } catch (error) {
        if (!this.$rest.isCancel(error)) {
          this.error = error.message
          this.loading = false
        }
        return { items: [], totalItems: 0 }
      }
    },
    exportReport: function () {
      this.enableExport = false
      this.getUserList().then(data => {
        this.exportedReport = data.items
      })
    },
    getFilters () {
      const filterBy = {}
      if (this.filter === 'dealer' && this.search) {
        Object.assign(filterBy, { dealer: this.search })
      }
      if (this.filter === 'stat' && this.stat) {
        Object.assign(filterBy, { stat: this.stat })
      }
      Object.assign(filterBy, { day: this.day })
      Object.assign(filterBy, { select: this.select })

      // return this.filter && (this.search || this.stat) ? filterBy : null
      return this.filter &&
      (this.search || this.stat || this.day || this.select) ? filterBy : null
    },
    cancelRequest: function () {
      if (this.source) {
        this.source.cancel('Forced cancellation.')
        this.source = null
      }
    },
    refreshList () {
      this.getUserList().then(data => {
        this.items = data.items
      })
    },
    resetList () {
      this.items = []
    },
    saveMenu (date) {
      this.$refs.menu.save(date)
    },
    showError (message) {
      this.alertType = 'error'
      this.alertMessage = message
      this.alert = true
    },
  },
}
</script>
